import Foundation from 'foundation-sites';
import $ from 'jquery';
import select2 from 'select2';
import 'what-input';
  // import Swiper bundle with all modules installed
  import Swiper from 'swiper/bundle';


// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
// The global variables for jQuery are intentionally commented out - jQuery is pulled from a CDN in the head tag, since
//   that appears to be the path of least resistance for integration the Telerik controls, which also use jQuery. If jQuery
//   is not included in the head tag, some Telerik controls (specifically the upload control) will throw errors.
//window.jQuery = $;
//window.$ = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

const swiper4 = new Swiper('.myswiper4', {
  // Optional parameters
  slidesPerView: 6,
  spaceBetween: 30,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  }});
const swiper = new Swiper('.swiper', {
  // Optional parameters
  slidesPerView: 2,
    spaceBetween: 30,
    breakpoints: {
        640: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
        1440: {
            slidesPerView: 6,
            spaceBetween: 50,
        },
    },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,}});

    const swiper3 = new Swiper(".mySwiper3", {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    const swiper2 = new Swiper(".mySwiper2", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper3,
      },
    });

    
      var swiperHome = new Swiper(".swiperHome", {
          slidesPerView: "auto",
          centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    
      var swiperNews = new Swiper(".swiperNews", {
        pagination: {
          el: ".swiper-paginationNews",
          clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          autoplay: false,
          loop: true,
          slidesPerView: "1",
          centeredSlides: true
      });

    var swiperFeaturedItems = new Swiper(".swiperFeaturedItems", {
      breakpoints: {
        // when window width is >= 320px
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 480px
        1024: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 640px
        1200: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      },
      slidesPerView: "1",
        centeredSlides: true,
        effect: 'coverflow',
    spaceBetween: 50,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
        autoplay: false,
        loop: false
    });

    const categorySliderBreakPoints = {
        // when window width is >= 320px
        640: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        // when window width is >= 480px
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        // when window width is >= 640px
        1200: {
            slidesPerView: 4,
            spaceBetween: 40
        }
    };

const swiperCategoryApplications = new Swiper(".swiperCategoryApplications", {
    slidesPerView: "1",
    centeredSlides: true,
    spaceBetween: 0,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    autoplay: false,
    loop: true
});

const swiperCategoryFeatures = new Swiper(".swiperCategoryFeatures", {
    breakpoints: categorySliderBreakPoints,
    slidesPerView: "1",
    centeredSlides: true,
    spaceBetween: 50,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    autoplay: false,
    loop: false
});

const swiperCategoryCustomization = new Swiper(".swiperCategoryCustomization", {
    breakpoints: categorySliderBreakPoints,
    slidesPerView: "1",
    centeredSlides: true,
    spaceBetween: 50,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    autoplay: false,
    loop: false
});

const swiperCategoryTechnicalInformation = new Swiper(".swiperCategoryTechnicalInformation", {
    breakpoints: categorySliderBreakPoints,
    slidesPerView: "1",
    centeredSlides: true,
    spaceBetween: 50,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    autoplay: false,
    loop: false
});

const swiperCategoryHowToOrder = new Swiper(".swiperCategoryHowToOrder", {
    breakpoints: categorySliderBreakPoints,
    slidesPerView: "1",
    centeredSlides: true,
    spaceBetween: 50,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    autoplay: false,
    loop: false
});



      $(document).ready(function() {
        $('.makeSelect2').select2({
          theme: "foundation"
        });
      });

function runSearch() {
    const keywords = document.querySelector('#search-keywords').value.trim();
    if (keywords) {
        document.location.href = `/advanced-search?Keywords=${keywords}&Search=1`;
    }
}

window.submitFormKey = (e, strSubmitButtonElementID) => {
    var btnSubmit = document.getElementById(strSubmitButtonElementID);

    if (btnSubmit != null) {
        if ((e && e.which && e.which == 13) ||
            (e.keyCode && e.keyCode == 13)) {

            e = e ? e : window.event;
            if (e.stopPropagation)
                e.stopPropagation();
            if (e.preventDefault)
                e.preventDefault();
            e.cancelBubble = true;
            e.cancel = true;
            e.returnValue = false;
            var commandName = strSubmitButtonElementID.split("_").join("$");
            eval("window.setTimeout('__doPostBack(\"" + commandName + "\", \"\");', 1);");
            return false;
        }
        else
            return true;
    }
}

document.addEventListener('DOMContentLoaded', e => {
    document.querySelector('#run-search')?.addEventListener('click', e => {
        e.preventDefault();
        runSearch();
    });

    document.querySelector('#search-keywords')?.addEventListener('keypress', e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            runSearch();
        }
    });

    document.querySelector('#open-search-panel')?.addEventListener('click', e => {
        e.preventDefault();

        setTimeout(function () {
            document.querySelector('#search-keywords')?.focus();
        }, 100)
    })
});

window.ScrollToTop = () => self.scrollTo(0, 0);

    $(document).foundation();